// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-standalone-embed-page-tsx": () => import("./../../../src/components/StandaloneEmbedPage.tsx" /* webpackChunkName: "component---src-components-standalone-embed-page-tsx" */),
  "component---src-components-standalone-page-tsx": () => import("./../../../src/components/StandalonePage.tsx" /* webpackChunkName: "component---src-components-standalone-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-9-avril-index-tsx": () => import("./../../../src/pages/9avril/index.tsx" /* webpackChunkName: "component---src-pages-9-avril-index-tsx" */),
  "component---src-pages-embed-map-tsx": () => import("./../../../src/pages/embed/map.tsx" /* webpackChunkName: "component---src-pages-embed-map-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

